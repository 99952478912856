import { SEO } from '@components/common'
import Error404 from '@components/error/Error404'

const Error404Page = () => (
    <>
        <SEO title="Eroare 404" description="Pagina nu a fost gasita" />
        <Error404 />
    </>
)
export default Error404Page

import React from 'react'
import Link from 'next/link'
import { Container, Typography } from '@material-ui/core'

import { SPButton } from '@components/common'
import classes from './Error404.module.scss'

const Error404 = () => {
    return (
        <div className={classes.container}>
            <Container
                maxWidth="sm"
                style={{ paddingLeft: '4rem', paddingRight: '4rem' }}
            >
                <div className={classes.top__container}>
                    <Typography component="span">404</Typography>
                    <div className={classes.border} />
                    <div>
                        <Typography component="h1">
                            Pagina nu a fost gasita
                        </Typography>
                        <Typography>Verifica linkul si incearca din nou</Typography>
                    </div>
                </div>

                <br />
                <Link href="/" passHref>
                    <a>
                        <SPButton
                            fullWidth
                            variant="contained"
                            text="Mergi pe prima pagina"
                        />
                    </a>
                </Link>
            </Container>
        </div>
    )
}

export default Error404
